<template>
  <div>
    <div class="search_container">
      <div>
        <span>账户名称：</span>
        <a-input
          @keydown.native.enter="handleSearch"
          class="input_width"
          placeholder="请输入"
          v-model="username"
        />
      </div>
      <div>
        <span>企业名称：</span>
        <a-input
          @keydown.native.enter="handleSearch"
          class="input_width"
          placeholder="请输入"
          v-model="applyName"
        />
      </div>
      <div>
        <span>手机号码：</span>
        <a-input
          @keydown.native.enter="handleSearch"
          class="input_width"
          placeholder="请输入"
          v-model="phone"
        />
      </div>
      <div>
        <span>审核状态：</span>
        <a-select v-model="status" class="input_width">
          <a-select-option
            v-for="(item, index) in statusList"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>

      <div>
        <a-button @click="reset"> 重置 </a-button>
        <a-button
          style="margin-left: 20px"
          @click="handleSearch"
          type="primary"
        >
          查询
        </a-button>
      </div>
    </div>
    <div style="margin-top: 20px">
      <a-table
       :loading="loading"
        style="min-width: 1500px"
        :pagination="pagination"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="dataTable"
         :scroll="{ y: 379 }"
      >
        <span slot="AuditStatus" slot-scope="text">
          <span style="color: #faad14" v-if="text === 1">待审核</span>
          <span style="color: #52c41a" v-if="text === 2">已通过</span>
          <span style="color: #ff4d4f" v-if="text === 3">未通过</span>
        </span>
        <span slot="action" slot-scope="text, record">
          <a v-if="record.status === 1" @click="openModel('handle', record)"
            >审核</a
          >
          <a
            style="margin-right: 20px"
            v-if="record.status === 2"
            @click="openModel('edit', record)"
            >修改资料</a
          >
          <a
            v-if="record.status === 2 || record.status === 3"
            @click="openModel('look', record)"
            >查看资料</a
          >
        </span>
      </a-table>
    </div>
    <ModelTag
      v-if="modelConfig.show"
      @handleCancel="handleCancel"
      @handleOk="handleOk"
      :config="modelConfig"
    ></ModelTag>
  </div>
</template>

<script>
import pagination from "@/mixins/Pagination";
import ModelTag from "./model.vue";
import { businessReviewApi } from "@/api/businessReview.js";

export default {
  mixins: [pagination],
  components: {
    ModelTag,
  },
  data() {
    return {
      loading:false,
      username: "",
      applyName: "",
      phone: "",
      status: 0,
      modelConfig: {
        type: "add",
        id: "",
        show: false,
        record: null,
        title: "人员班组",
        width: "60%",
        okText: "保存",
        delArr: [],
        status: false,
      },

      statusList: [
        {
          name: "全部",
          value: 0,
        },
        {
          name: "待审核",
          value: 1,
        },
        {
          name: "审核通过",
          value: 2,
        },
        {
          name: "审核失败",
          value: 3,
        },
      ],
      columns: [
        {
          title: "账号名称",
          dataIndex: "username",
          key: "username",
          align: "center",
        },
        {
            ellipsis: true,
          title: "企业名称",
          dataIndex: "applyName",
          key: "applyName",
          align: "center",
          // scopedSlots: { customRender: "status" },
          // width: "110px",
        },
        {
          title: "手机号码",
          dataIndex: "phone",
          key: "phone",
          align: "center",
          // width: "110px",
        },
        {
          title: "申请时间",
          dataIndex: "createTime",
          key: "createTime",
          align: "center",
          // width: "160px",
        },
        {
          title: "审核人",
          dataIndex: "reviewer",
          key: "reviewer",
          align: "center",
          // width: "160px",
        },

        {
          title: "审核时间",
          dataIndex: "auditTime",
          key: "auditTime",
          align: "center",
          // width: "160px",
        },
        {
          title: "审核状态",
          dataIndex: "status",
          key: "status",
          align: "center",
          scopedSlots: { customRender: "AuditStatus" },
          width: "110px",
        },

        {
          title: "操作",
          align: "center",
          key: "action",
          scopedSlots: { customRender: "action" },
           width: "210px",
        },
      ],
      dataTable: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    //重置
    reset() {
      this.username = "";
      this.applyName = "";
      this.phone = "";
      this.status = 0;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getData();
    },
    //查询数据接口
    getData() {
      this.loading=true
      let params = {
        username: this.username,
        status: this.status,
        applyName: this.applyName,
        phone: this.phone,
        type: 1, //企业账户 1，企业开发者 2，个人开发者 3
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      businessReviewApi.getTableData(params).then((res) => {
        if (res.success) {
           this.loading=false
          this.pagination.total = Number(res.data.total);
          this.dataTable = res.data.records;
        } else {
           this.loading=false
          this.$message.error(res.errorMsg);
        }
      });
    },
    //查询
    handleSearch() {
      this.pagination.current = 1;
      this.getData();
    },

    //调用弹框事件
    openModel(type, record) {
      this.modelConfig.id = record ? record.id : null;
      this.modelConfig.type = type;
      this.modelConfig.show = true;
      if (type === "edit") {
        this.modelConfig.record = record;
        this.modelConfig.title = "修改资料";
        this.modelConfig.width = "800px";
        this.modelConfig.status = false;
      } else if (type === "handle") {
        this.modelConfig.record = record;
        this.modelConfig.title = "审核";
        this.modelConfig.width = "800px";
        this.modelConfig.okText = "确认";
        this.modelConfig.status = false;
      } else {
        this.modelConfig.record = record;
        this.modelConfig.title = "查看资料";
        this.modelConfig.width = "800px";
        this.modelConfig.status = true;
      }
    },

    //弹框关闭
    handleCancel() {
      this.modelConfig.show = false;
    },
    //弹框保存按钮
    handleOk() {
      this.modelConfig.show = false;
      this.$message.success("保存成功！");
      this.reset();
    },
    // 分页改变
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getData();
    },
    //分页
    onPage(current) {
      this.pagination.current = current;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.input_width {
  width: 240px;
}
.search_container {
  display: flex;
  justify-content: space-between;
  min-width: 1500px;
  overflow: auto;
}
.add_button {
  margin-top: 20px;
  margin-bottom: 20px;
}
/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}

/deep/.ant-table-header {
  min-width: 0px !important;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
}
  /deep/.ant-table {
    min-height: 379px;
    }
</style>