<template>
  <div>
    <a-modal
      v-model="config.show"
      style="padding: 0px; margin-top: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
    >
      <template slot="footer">
        <a-button
          v-if="config.type === 'edit' || config.type === 'handle'"
          @click="handleCancel"
        >
          取消
        </a-button>
        <a-button v-if="config.type === 'handle'" @click="openInnerModel">
          不通过
        </a-button>
        <a-button v-if="config.type === 'look'" @click="handleCancel">
          关闭
        </a-button>
        <a-button
          v-if="config.type === 'handle'"
          key="submit"
          type="primary"
          @click="handleOk"
        >
          通过
        </a-button>
        <a-button
          v-if="config.type === 'edit'"
          key="submit"
          type="primary"
          @click="handleOk"
        >
          保存
        </a-button>
      </template>

      <a-spin :spinning="spinning">
        <div>
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            class="formContent"
          >
            <a-form-model-item label="申请人信息">
              <div>{{ config.record.username }} {{ config.record.phone }}</div>
            </a-form-model-item>
            <a-form-model-item label="申请时间">
              <div>{{ config.record.createTime }}</div>
            </a-form-model-item>
            <a-form-model-item label="企业名称" prop="applyName">
              <a-input
                :maxLength="100"
                class="formInputWidth"
                :disabled="config.status"
                v-model="form.applyName"
                placeholder="请输入"
              />
            </a-form-model-item>
            <a-form-model-item
              label="统一社会信用代码"
              prop="unifiedSocialCreditCode"
            >
              <a-input
                class="formInputWidth"
                :disabled="config.status"
                v-model="form.unifiedSocialCreditCode"
                placeholder="请输入"
              />
            </a-form-model-item>
            <a-form-model-item label="营业执照" prop="license">
              <span class="notice_text">
                支持格式：PNG、JPG或JPEG或PDF，单个文件不超10M
              </span>
              <UploadTag
                :config="uploadConfig"
                @getImageUrl="getLicenseUrl"
              ></UploadTag>
            </a-form-model-item>
            <a-form-model-item label="营业执照有效期" prop="timeArr">
              <a-range-picker
                @change="onTimeChange"
                v-model="form.timeArr"
                :disabled="config.status"
                class="formInputWidth"
              />
            </a-form-model-item>
            <a-form-model-item label="企业法人" prop="legalPerson">
              <a-input
                :maxLength="20"
                class="formInputWidth"
                :disabled="config.status"
                v-model="form.legalPerson"
                placeholder="请输入"
              />
            </a-form-model-item>
            <a-form-model-item label="法人联系方式" prop="contact">
              <a-input
                class="formInputWidth"
                :disabled="config.status"
                v-model="form.contact"
                placeholder="请输入"
              />
            </a-form-model-item>
            <a-form-model-item label="法人身份证" prop="idCard">
              <div class="idCard_contain">
                <div class="idCard">
                  <UploadTag
                  
                    :config="uploadIdcardConfig"
                    @getImageUrl="getIdcardUrl"
                  ></UploadTag>
                  <span class="idText">身份证人像面</span>
                </div>
                <div class="idCard">
                  <UploadTag
                    :config="uploadIdcardBgConfig"
                    @getImageUrl="getIdcardBgUrl"
                  ></UploadTag>
                  <span class="idText">身份证国徽面</span>
                </div>
              </div>
            </a-form-model-item>
            <a-form-model-item label="授权证明" prop="authorizeProve">
              <span class="notice_text">
                支持格式：PNG、JPG或JPEG或PDF，单个文件不超10M， 需要公司盖章
              </span>
              <UploadTag
                :config="uploadAuthorizeProveConfig"
                @getImageUrl="getAuthorizeProveUrl"
              ></UploadTag>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-spin>
      <InnerModel
        v-if="modelConfig.show"
        @handleOk="handleInnerOk"
        @handleCancel="handleInnerCancel"
        :config="modelConfig"
      ></InnerModel>
    </a-modal>
  </div>
</template>

<script>
import { businessReviewApi } from "@/api/businessReview.js";
import UploadTag from "../../components/uploadTag.vue";
import InnerModel from "./innerModel.vue";

export default {
  components: {
    UploadTag,
    InnerModel,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
          status: false,
        };
      },
    },
  },
  data() {
    //校验身份证
    let validatorIdcard = (rule, value, callback) => {
      if (this.idCardFront && this.idCardBackend) {
        callback();
      } else {
        callback(new Error("身份证照片信息不完整！"));
      }
    };
    //校验信用社
    let validatorUnifiedSocialCreditCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("统一社会信用代码不能为空"));
      } else if (!/^[0-9a-zA-Z_]{1,18}$/.test(value) && value) {
        callback(new Error("统一社会信用代码格式不正确"));
      } else {
        callback();
      }
    };
    //校验法人联系方式
    let validatorUser = (rule, value, callback) => {
      if (!value) {
        callback(new Error("法人联系方式不能为空"));
      } else if (!/^([0-9]|[-]|[+]){1,20}$/.test(value) && value) {
        callback(new Error("法人联系方式格式不正确"));
      } else {
        callback();
      }
    };
    return {
      spinning: false,
      confirmLoading: false,
      detailId: null, //详情Id
      validDate: "", //开始时间
      invalidDate: "", //结束时间
      idCardFront: "", //身份证证明
      idCardBackend: "", //身份证背面
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      modelConfig: {
        id: "",
        show: false,
        record: null,
        width: "600px",
      },
      uploadConfig: {
        barrelType: "secret",
        uploadType: "default",
        showPreview: true, //是否展示预览图标
        showRemove: true, //是否展示删除图标
        size: 10, //限制文件大小
        limitLength: 1, //限制上传文件数量
        limitControl: true, //是否限制照片墙数量
        fileList: [], //文件列表
        limitType: ["image/jpeg", "image/png", "image/jpg", "application/pdf"],
      },
      uploadIdcardConfig: {
        barrelType: "secret",
        uploadType: "picture",
        showPreview: true, //是否展示预览图标
        showRemove: true, //是否展示删除图标
        size: 10, //限制文件大小
        limitLength: 1, //限制上传文件数量
        limitControl: true, //是否限制照片墙数量
        idCard: true,
        cardType: 1,
        fileList: [], //文件列表
      },
      uploadAuthorizeProveConfig: {
        barrelType: "secret",
        uploadType: "default",
        showPreview: true, //是否展示预览图标
        showRemove: true, //是否展示删除图标
        size: 10, //限制文件大小
        limitLength: 1, //限制上传文件数量
        limitControl: true, //是否限制照片墙数量
        idCard: false,
        text: "上传授权证明",
        fileList: [], //文件列表
        limitType: ["image/jpeg", "image/png", "image/jpg", "application/pdf"],
      },
      uploadIdcardBgConfig: {
        barrelType: "secret",
        uploadType: "picture",
        showPreview: true, //是否展示预览图标
        showRemove: true, //是否展示删除图标
        size: 10, //限制文件大小
        limitLength: 1, //限制上传文件数量
        limitControl: true, //是否限制照片墙数量
        idCard: true,
        cardType: 2,
        fileList: [], //文件列表
      },
      form: {
        applyName: "",
        unifiedSocialCreditCode: "",
        license: "",
        timeArr: [],
        legalPerson: "",
        contact: "",
        idCard: "",
        authorizeProve: "",
      },

      rules: {
        applyName: [
          {
            required: true,
            message: "企业名称不能为空",
            trigger: "blur",
            //whitespace: true,
          },
        ],
        unifiedSocialCreditCode: [
          {
            required: true,
            validator: validatorUnifiedSocialCreditCode,
            trigger: "blur",
            //whitespace: true,
          },
        ],
        license: [
          {
            required: true,
            message: "营业执照不能为空",
            trigger: "blur",
          },
        ],
        timeArr: [
          {
            required: true,
            message: "营业执照有效期不能为空",
            trigger: "blur",
          },
        ],
        legalPerson: [
          {
            required: true,
            message: "企业法人不能为空",
            trigger: "blur",
          },
        ],
        contact: [
          {
            required: true,
            validator: validatorUser,
            trigger: "blur",
          },
        ],
        idCard: [
          {
            required: true,
            validator: validatorIdcard,
            trigger: "blur",
          },
        ],
        authorizeProve: [
          {
            required: true,
            message: "授权证明不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    // this.getReviewData()
    this.initModal();
  },
  watch: {},
  methods: {
    initModal() {
      if (this.config.type === "look") {
        this.uploadConfig.showRemove = false;
        this.uploadIdcardConfig.showRemove = false;
        this.uploadAuthorizeProveConfig.showRemove = false;
        this.uploadIdcardBgConfig.showRemove = false;
      } else {
        this.uploadConfig.showRemove = true;
        this.uploadIdcardConfig.showRemove = true;
        this.uploadAuthorizeProveConfig.showRemove = true;
        this.uploadIdcardBgConfig.showRemove = true;
      }
      this.getReviewData();
    },
    handleInnerOk() {
      this.modelConfig.show = false;
      this.$emit("handleOk");
    },
    //第二个弹框关闭
    handleInnerCancel() {
      this.modelConfig.show = false;
    },
    //第二个弹框打开
    openInnerModel() {
      this.modelConfig.id = this.detailId;
      this.modelConfig.record = {
        id: this.detailId,
        type: 1, //1.企业账户，2.企业开发者 3.个人开发者
        inspectType: 1, //1.审核，2修改
        inspectId: this.config.id,
        ...this.form,
        validDate: this.validDate,
        invalidDate: this.invalidDate,
        idCardFront: this.idCardFront,
        idCardBackend: this.idCardBackend,
        rowVersion: this.config.record.rowVersion,
        status: 3, //2.审核通过 ，3审核失败
      };
      this.modelConfig.show = true;
    },
    //获取弹框审核详细数据
    getReviewData() {
      this.spinning = true;
      let id = this.config.id;
      businessReviewApi.getReviewData(id).then((res) => {
        if (res.success) {
          this.detailId = res.data.id;
          this.form.applyName = res.data.applyName;
          this.form.unifiedSocialCreditCode = res.data.unifiedSocialCreditCode;
          this.form.license = res.data.license;
          this.uploadConfig.fileList.push({
            name: res.data.license.substring(
              res.data.license
            ),
            uid: res.data.license,
            status: "done",
            url: res.data.license,
          });
          this.validDate = res.data.validDate;
          this.invalidDate = res.data.invalidDate;
          this.form.timeArr = [res.data.validDate, res.data.invalidDate];
          this.form.legalPerson = res.data.legalPerson;
          this.form.contact = res.data.contact;
          this.uploadIdcardConfig.fileList.push({
            name: res.data.idCardFront.substring(
              res.data.idCardFront
            ),
            uid: res.data.idCardFront,
            status: "done",
            url: res.data.idCardFront,
          });
          this.idCardFront = res.data.idCardFront;
          this.uploadIdcardBgConfig.fileList.push({
            name: res.data.idCardBackend.substring(
              res.data.idCardBackend
            ),
            uid: res.data.idCardBackend,
            status: "done",
            url: res.data.idCardBackend,
          });
          this.idCardBackend = res.data.idCardBackend;
          
          this.uploadAuthorizeProveConfig.fileList.push({
            name: res.data.authorizeProve.substring(
              res.data.authorizeProve
            ),
            uid: res.data.authorizeProve,
            status: "done",
            url: res.data.authorizeProve,
          });
          console.log('截出的结果', res.data.authorizeProve)
          this.form.authorizeProve = res.data.authorizeProve;
          this.spinning = false;
        } else {
          this.$message.error(res.errorMsg);
          this.spinning = false;
        }
      });
    },

    //审核通过
    passReview() {
      this.confirmLoading = true;
      let params = {
        id: this.detailId,
        type: 1, //1.企业账户，2.企业开发者 3.个人开发者
        inspectType: 1, //1.审核，2修改
        inspectId: this.config.id,
        ...this.form,
        validDate: this.validDate,
        invalidDate: this.invalidDate,
        idCardFront: this.idCardFront,
        idCardBackend: this.idCardBackend,
        status: 2, //2审核通过 ，3审核失败
        rowVersion: this.config.record.rowVersion,
      };
      businessReviewApi.PassReview(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //修改成功
    editReview() {
      this.confirmLoading = true;
      let params = {
        id: this.detailId,
        type: 1, //1.企业账户，2.企业开发者 3.个人开发者
        inspectType: 2, //1.审核，2修改
        inspectId: this.config.id,
        ...this.form,
        validDate: this.validDate,
        invalidDate: this.invalidDate,
        idCardFront: this.idCardFront,
        idCardBackend: this.idCardBackend,
        rowVersion: this.config.record.rowVersion,
      };
      businessReviewApi.PassReview(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },

    // 时间日期事件
    onTimeChange(date, dateString) {
      this.validDate = dateString[0];
      this.invalidDate = dateString[1];
    },
    //获取营业执照地址
    getLicenseUrl(val, fileList) {
      this.form.license = val;
      this.uploadConfig.fileList = fileList;
    },
    //获取身份证正面照片地址
    getIdcardUrl(val, fileList) {
      console.log('val',val)
      console.log("fileList",fileList)
      this.idCardFront = val;
      this.uploadIdcardConfig.fileList = fileList;
    },
    //获取身份证背面地址
    getIdcardBgUrl(val, fileList) {
      this.idCardBackend = val;
      this.uploadIdcardBgConfig.fileList = fileList;
    },
    //获取上传授权证明地址
    getAuthorizeProveUrl(val, fileList) {
      this.form.authorizeProve = val;
      this.uploadAuthorizeProveConfig.fileList = fileList;
    },
    handleCancel() {
      this.$emit("handleCancel");
    },

    handleOk() {
      if (this.config.type === "handle") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.passReview();
          } else {
            return false;
          }
        });
      } else if (this.config.type === "edit") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.editReview();
          } else {
            return false;
          }
        });
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.formContent {
  width: 100%;
}
.formInputWidth {
  width: 500px;
}
.notice_text {
  color: rgba(0, 0, 0, 0.39);
  font-size: 12px;
}
.add_positon {
  position: relative;
}
.modal_content {
  display: flex;
}
.modal_left {
  min-width: 120px;
  text-align: right;
}
.modal_right {
  padding-bottom: 10px;
  flex-grow: 1;
}
.modal_text {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding-top: 4px;
  .modal_logo {
    margin-left: 10px;
    margin-top: -6px;
  }
}
.modal_stext {
  color: rgb(136, 131, 131);
  font-size: 12px;
  padding-left: 30px;
  font-weight: 200;
  height: 60px;
}

.header_contain {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  padding-top: 20px;
}
.idCard_contain {
  display: flex;
  height: 220px;
}
.idText {
  position: absolute;
  bottom: 20px;
  left: 80px;
}
.idCard {
  position: relative;
}
.idCard /deep/.ant-upload-list-picture-card .ant-upload-list-item {
  width: 240px;
  height: 160px;
  margin-bottom: 230px;
}
.idCard /deep/ .ant-upload-list-picture-card-container {
  width: 240px;
  height: 140px;
}
/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
/deep/.ant-table-header {
  min-width: 0px !important;
}
</style>